import {
  ActivitySummaryResponseModel,
  DurationStatisticsResponseModel,
  insightsAPI,
} from '@capturi/api-insights'
import { Segment, useFetchSegments } from '@capturi/filters'
import { PlaySnippetsButton } from '@capturi/ui-components'
import { t } from '@lingui/macro'
import { ColumnDefinition } from 'components/DataGrid'
import React from 'react'
import formatSeconds from 'utils/formatSeconds'

import {
  UserSegmentsData,
  useConversationDataStats,
} from '../../data/useConversationDataStats'
import Breakdown from '../Breakdown'

type Props = {
  summaries: Segment<ActivitySummaryResponseModel>[]
  onPlaySnippets: (row: UserSegmentsData, segmentIndex: number) => void
}

export const UserBreakdown = React.memo<Props>(
  ({ summaries, onPlaySnippets }) => {
    const { segments, isLoading } =
      useFetchSegments<DurationStatisticsResponseModel>(
        insightsAPI.getDurationStatistics,
      )

    const userSegmentsData = useConversationDataStats(segments, 'avg')

    const columns = React.useMemo(() => {
      const columns: ColumnDefinition<UserSegmentsData>[] = [
        {
          type: 'user',
          getValue: (d) => d.user.uid,
        },
        {
          type: 'value',
          getSegmentValue: (s) => s.data?.avg,
          formatValue: formatSeconds,
          alignRight: true,
        },
        {
          type: 'progress',
          getSegmentValue: (s) => s.data?.avg,
          referenceLines:
            userSegmentsData.length === 0
              ? []
              : summaries.map((s) => ({
                  label: t`Avg. ${
                    s.data == null ? '' : formatSeconds(s.data.durationAverage)
                  }`,
                  color: s.color,
                  value: s.data?.durationAverage,
                })),
        },
        {
          type: 'dataBasis',
          getSegmentValue: (s) => {
            const { conversations = 0, conversationsTotal = 0 } = s.data ?? {}
            if (conversations === conversationsTotal) {
              return conversations
            }
            return {
              value: conversations,
              total: conversationsTotal,
            }
          },
        },
        {
          type: 'button',

          render: (row, segmentIndex) => (
            <PlaySnippetsButton
              label={t`View conversations`}
              onClick={() => onPlaySnippets(row, segmentIndex)}
            />
          ),
        },
      ]
      return columns
    }, [userSegmentsData.length, summaries, onPlaySnippets])

    return (
      <Breakdown
        columns={columns}
        data={userSegmentsData}
        isLoading={isLoading}
      />
    )
  },
)
